import React, {useState} from 'react';
import { Button } from '@material-ui/core';
import './App.css';
import SimpleTable from './SimpleTable';
import cyan from '@material-ui/core/colors/cyan';
import red from '@material-ui/core/colors/red';

const primary = cyan[400]; // #F44336
const accent = cyan['A200']; // #E040FB

function App() {
  const [password, setPassword] = useState(""); 
  const arrLecture = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX_i_X', 'XI', 'XII'];

  function createData(name, title) {
    return { name, title };
  }
  const rowsLectures = [
    createData('2020-03-05', 'WYKŁAD 1 "Teoria kompozycji, liternictwo" prof. PP, S. Rosolski'),
    createData('', 'WYKŁAD 2 "Teoria kompozycji, punkt i linia" prof. PP, S. Rosolski'),
    createData('2020-03-12', 'WYKŁAD 3  "Geometryczne  środki  wyrazu  kształtowania  przestrzeni; punkt linia płaszczyzna" T. Jastrząb  '),
    createData('', 'WYKŁAD  4  "Geometryczne  środki  wyrazu  kształtowania  przestrzeni; bryła i ruch  w  przestrzeni " T. Jastrząb'),
    createData('2020-03-19', 'WYKŁAD 5 "Kształtowanie  formy  architektonicznej" dr  T. Jastrząb  '),
    createData('', 'WYKŁAD 6 "Zasady  artykulacji  przestrzennej,  prawa kompozycji" dr T. Jastrząb'),
    createData('2020-03-26', 'WYKŁAD 7 "Podstawy kompozycji spoistej. Przestrzeń" dr M. Kozaczko'),
    createData('', 'WYKŁAD 8  " Podstawy kompozycji spoistej. Czasoprzestrzeń" dr M. Kozaczko'),
    createData('2020-04-02', 'WYKŁAD 9 i 10 "Proporcje i skala w architekturze" dr  T. Jastrząb'),
    createData('', ''),
    createData('2020-04-09', 'WYKŁAD 11 „Skala i proporcje” dr T. Jastrząb'),
    createData('', 'WYKŁAD 12 „Analiza  przestrzeni - rodzaje  organizacji  przestrzennych” dr T. Jastrząb'),
  ];
  
  const rowsPractices = [
    createData('2020-04-16', 'opracowanie na formacie A3 tekstu dowolnej treści (na sztywnym podkładzie przystosowanym do prezentacji na sztaludze) - opracowanie własnego kroju liter (czcionki),'),
    createData('', 'opracowanie na formacie A3  tekstu dowolnej treści (na sztywnym podkładzie przystosowanym do prezentacji na sztaludze)- opracowanie na podstawie wybranego/ ogólnie dostępnego kroju liter  (czcionki).'),
    createData('2020-04-23', 'Punkt zrównoważony przez linie " Forma  pracowania: technika dowolna, format: A3'),
    createData('2020-04-30', `„Przekształcenia siatki” -Dokonaj przekształceń siatki - uwzględniając minięcie siatek, wypadania  elementów, scalanie, cięcia  układu
    Zilustruj poszczególne etapy transformacji kompozycji. Wersję końcową przedstaw w trzecim wymiarze, wyróżniając formy kontrastujące z otoczeniem pod względem wymiarów, kształtu i położenia (zasada hierarchii) (Format A 3, rysunek i makieta)
    `),
    createData('2020-05-07', `„Forma i tło” - subiektywne zmiany obiektywnie niezmiennej formy – forma podstawowa i 3 warianty
    (Format A3, technika dowolna)
    `),
    createData('2020-05-14', `Ćw. 6. Zaprojektuj dwie kompozycje złożone z trzech połączonych ze sobą sześcianów o odmiennych cechach. Kompozycja pierwsza - spoista, symetryczna, statyczna. Kompozycja druga - swobodna, asymetryczna, dynamiczna. 
    (Format A 3, makieta )
    `),
    createData('2020-05-21', `Ćw. 7 „Niewidzialne miasta” swobodne przedstawienie przestrzeni miast w otwartej interpretacji graficznej, przestrzennej lub instalacji artystycznej
    `),
    createData('2020-05-28', 'Ocena prac'),
  ];

  const goToLink = () =>{
    return(
      <>
        {
          arrLecture.map(ID => {
            const key = `REACT_APP_WYK_${ID}`;
            return(<a
            href={`${process.env[key]}.pdf`}
            target="_blank"
            rel="lecture"
            >Wykład {ID} - {process.env[key]}</a>)
            }
          )
        }
    </>
    )
  }
  
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Wykłady z teorii kompozycji 2020
        </p>
        <p>
          Wprowadź hasło
        </p>
        <input type="text" value={password} onChange={event => setPassword(event.target.value)} />
        {password === process.env.REACT_APP_NOT_SECRET_CODE && 
        goToLink()}
      </header>
      <SimpleTable rows={rowsLectures} metaTitle="Data wykładu" style={{backgroundColor: "white"}}/>
      <SimpleTable rows={rowsPractices} metaTitle="Data ćwiczeń" style={{backgroundColor: primary}}/>
    </div>
  );
}

export default App;
